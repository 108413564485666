.searchBox{
    width: 78%;
    padding: 10px 20px;
    border:1px solid #fff;
    outline: none;
    background-color: #000;
    border-radius: 20px;
    color: #fff;
}
.btn-search{
    border-radius: 20px;
    padding: 10px 20px;
    color: #fff;
    width: 19%;
    border:1px solid #5E17EB;
    background-color: #5E17EB !important;
    margin-top: -1px;
}
.searchBox::placeholder{
    color: #fff !important;
}
.btn-search:hover{
    background-color: #5E17EB;
}
