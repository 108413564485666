body {
  background-color: #000;
}

.text-red {
  color: #EA4335 !important;
}

.text-cyan {
  color: #38B6FF !important;
}
.text-blue{
  color:#5E17EB !important;
}

.bg-dark{
  background-color: #000 !important;
}
@media (max-width:2000px) {
  .mobilebi {
      height: 500px;
  }

}
@media (min-width:2000px) {
  .mobilebi {
      height: 1400px;
  }

}