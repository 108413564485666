.searchBox1 {
    width: 78%;
    padding: 10px 20px;
    border: 1px solid #fff;
    outline: none;
    background-color: #000;
    border-radius: 20px;
    color: #fff;
    
}

.btn-search1 {
    border-radius: 20px;
    padding: 10px 20px;
    color: #fff;
    width: 19%;
    border: 1px solid #5E17EB;
    margin-top: -1px;
}

.searchBox1::placeholder {
    color: #fff !important;
}

.btn-search1:hover {
    background-color: #5E17EB;
}

.setup-menu li {
    margin: 0px 10px !important;
    padding: 3px 5px;
    font-weight: bold;
    cursor: pointer;
}

.text-blue {
    color: #5E17EB;
}

.setup-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center
}

.setup-table tr {
    background-color: #13181B !important;
}

.setup-table tr,
td {
    padding: 10px 5px;
    font-size: 12px;
}
.bar {
    height: 80vh !important;
    overflow-y: scroll;

}

.bar::-webkit-scrollbar {
    width: 5px !important;
}

/* Track */
.bar::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
.bar::-webkit-scrollbar-thumb {
    background: #FBBC05 !important;
    border-radius: 10px;

}